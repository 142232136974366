body {
    background-color: #0082c8;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    min-width: 1200px;

    #header {
        background-color: #0082c8;
        height: 60px;
        left: 0px;
        position: relative;
        top: 0px;
        width: 100%;
        z-index: 1;

        #posterSizes {
            color: #fff;
            left: 20px;
            opacity: 0.7;
            position: absolute;
            top: 15px;
            transition: opacity .2s;
            width: 325px;

            &:hover {
                opacity: 1;
            }

            .posterSize {
                background-color: rgba(0, 0, 0, 0.4);
                cursor: pointer;
                display: inline-block;
                padding: 2px 10px;

                &:hover:not(.selected) {
                    background-color: rgba(50, 50, 50, 0.4);
                }

                &.selected {
                    background-color: rgba(75, 75, 75, 0.4);
                }
            }
        }

        #tabBar {
            color: #fff;
            font-size: 24px;
            height: 32px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            top: 15px;
            width: 250px;

            div.tab {
                cursor: pointer;
                display: inline-block;
                margin: 0px;
                text-align: center;

                &.movies {
                    margin-right: 15px;
                    width: 100px;
                }
                &.tvShows {
                    width: 125px;
                }
            }

            #slider {
                background-color: rgba(255, 255, 255, 0.3);
                border-radius: 18px;
                height: 35px;
                left: -8px;
                position: absolute;
                top: -3px;
                transition: left .5s, width .5s;
                width: 115px;

                &.tvShows {
                    left: 111px;
                    width: 145px;
                }
            }
        }

        #filterInput {
            background: transparent;
            border: none;
            border-bottom: rgba(200, 200, 200, 0.6) solid 1px;
            color: #fff;
            font-size: 20px;
            padding: 0px 10px 5px;
            position: absolute;
            right: 20px;
            top: 15px;
            width: 300px;

            &::placeholder {
                color: #ddd;
            }

            &:focus {
                border-bottom-color: #04a;
                outline: none;
            }
        }
    }

    #postersContainer {
        height: calc(100vh - 60px);
        left: 20px;
        overflow: auto;
        position: relative;
        top: 0px;
        width: calc(100% - 40px);

        #postersList {
            color: #fff;
            margin-bottom: 20px;
        }
    }
}
