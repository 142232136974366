#moviesList, #tvShowsList {
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    justify-content: space-between;
    position: relative;

    .movie, .tvShow {
        display: inline-block;
        padding: 10px 10px 15px;
        position: absolute;
        width: 180px;

        &.franchiseTitle {
            background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(50, 50, 50, 0.5));

            .name {
                font-size: 40px;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &.franchise {
            background-color: rgba(50, 50, 50, 0.5);
        }

        .poster {
            background-color: rgba(255, 255, 255, 0.3);
            overflow: hidden;
            padding-top: 150%; /* 2:3 Aspect Ratio */
            position: relative; /* If you want text inside of it */
            width: 100%;

            .name {
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            img {
                left: 0px;
                position: absolute;
                top: 0px;
                width: 100%;
            }

            .info {
                background-color: rgba(50, 50, 50, 0.75);
                cursor: default;
                height: 100%;
                left: 0px;
                opacity: 0;
                position: absolute;
                top: 0px;
                transition: opacity .5s;
                width: 100%;

                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: center;

                &:hover {
                    opacity: 1;
                }

                span {
                    margin: 5px 10px;
                    text-align: center;
                }
            }
        }

        .name {
            font-size: 14px;
            margin-top: 5px;
            // opacity: 0;
            text-align: center;
            transition: opacity .3s;
        }

        .attribute_4K {
            background-color: rgba(50, 50, 50, 0.7);
            border-radius: 6px;
            font-size: 16px;
            line-height: 16px;
            padding: 5px 10px;
            position: absolute;
            right: 20px;
            top: 20px;
        }
    }
}